import { createFeatureSelector, createSelector } from "@ngrx/store";

export enum TaskStatus {
    PENDING = 'PENDING',
    PROCESSED = 'PROCESSED',
}

export interface Task {
    _id: string;
    createdAt: Date;
    status: TaskStatus;
    taskData: {
        fileId: string;
        docRef: string;
    };
    taskType: string;
    updatedAt: Date;
    workflowId: string;
}

export interface TasksState {
    tasks: Task[]
}

export const initialTasksState: TasksState = {
    tasks: [],
};

const SELECT_TASKS = 'tasks'
const selectTasks = createFeatureSelector<TasksState>(SELECT_TASKS);

export const selectTasksSelector = createSelector(
    selectTasks,
    (state: TasksState) => state
);