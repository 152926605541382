import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { TaskDto } from "../../common/api-dtos/responses/task.dto";
import { ResumeParsePriceFile } from "../../common/api-dtos/requests/resume-parse-price-file.dto";

@Injectable({ providedIn: 'root' })
export class TasksService {

    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly ADMIN_ENDPOINT = `${this.BASE_URL}/v1/admin`

    getTasks() {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<TaskDto[]>(`${this.ADMIN_ENDPOINT}/tasks`, options);
    }

    getTaskDownloadUrl(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<{ url: string }>(`${this.ADMIN_ENDPOINT}/tasks/${id}/get-download-signed-url`, options);
    }

    getTaskUploadSignedUrl(type: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const params = { fileType: type }
        const options = HttpRequestHelper.getHttpOptions(headers, params);
        return this.http.get<{ url: string, name: string }>(`${this.ADMIN_ENDPOINT}/tasks/get-upload-signed-url`, options);
    }

    resumeParsePriceFile(workflowId: string, request: ResumeParsePriceFile) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<void>(`${this.BASE_URL}/v1/workflows/parse-price-file/${workflowId}/resume`, request, options);
    }

    resumeParseStockFile(workflowId: string, request: ResumeParsePriceFile) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<void>(`${this.BASE_URL}/v1/workflows/parse-stock-file/${workflowId}/resume`, request, options);
    }

}