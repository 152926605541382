import {createAction, props} from "@ngrx/store";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import { TableSaleOrder } from "../states/sale-orders.state";
import { Task } from "../states/tasks.state";
import { TaskResult } from "../../administration/components/task-actions/task-actions.component";
import { ResumeParsePriceFile } from "../../common/api-dtos/requests/resume-parse-price-file.dto";
import { ResumeParseStockFile } from "../../common/api-dtos/requests/resume-parse-stock-file.dto";

export const GET_TASKS = '[Tasks] Get Sale Tasks';
export const GET_TASKS_SUCCESS = '[Tasks] Get Tasks Success';
export const GET_TASKS_FAILURE = '[Tasks] Get Tasks Failure';
export const CLEAR_TASKS = '[Tasks] Clear Tasks';
export const DOWNLOAD_TASK_FILE = '[Tasks] Download Task File';

export const RESUME_PARSE_PRICE_FILE = '[Tasks] Resume Parse Price File';
export const RESUME_PARSE_STOCK_FILE = '[Tasks] Resume Parse Stock File';

export const getTasks = createAction(GET_TASKS);
export const getTasksSuccess = createAction(
  GET_TASKS_SUCCESS,
  props<{ tasks: Task[] }>()
);
export const getTasksFailure = createAction(GET_TASKS_FAILURE);
export const clearTasks = createAction(CLEAR_TASKS);

export const getTaskDownloadSignedUrl = createAction(DOWNLOAD_TASK_FILE, props<{ id: string }>())

export const resumeParsePriceFile = createAction(
  RESUME_PARSE_PRICE_FILE,
  props<{ 
    workflowId: string,
    request: ResumeParsePriceFile
  }>()
)

export const resumeParseStockFile = createAction(
  RESUME_PARSE_STOCK_FILE,
  props<{ 
    workflowId: string,
    request: ResumeParseStockFile
  }>()
)